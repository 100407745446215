/**
 * 评论请求模块
 */
import request from '@/request/index'

/**
 * 获取看板
 */
 export default function getData(url,params,newUrl) {
  return request({
    method: 'GET',
    url: '/' + url + (params?params:''),
    newUrl: newUrl
  })
}
