import Vue from 'vue'
import App from './App.vue'
import router from './router'
// 加载全局样式
import './style/reset.css'
import axios from 'axios'
import ElementUI from 'element-ui';
import 'element-ui/lib/theme-chalk/index.css';
import getData from './api/index'; // get data
import md5 from 'js-md5';
import tools from './utils/tools'; // tools

Vue.prototype.md5 = md5;

Vue.use(ElementUI);

Vue.prototype.axios = axios
Vue.prototype.getData = getData
Vue.prototype.tools = tools
Vue.config.productionTip = false
router.beforeEach((to,from,next) => {
  if (to.meta.title) {
    document.title = to.meta.title
  }
  next()
})

new Vue({
  router,
  render: h => h(App)
}).$mount('#app')
