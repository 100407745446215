import Vue from 'vue'
// 数字补0
const getZero = (num) =>{
    if (parseInt(num) < 10) {
        num = '0' + num;
    }
    return num;
}
// 提示框
const messageFun = (msg, type, duration) => {
    Vue.prototype.$message({
        showClose: true,
        message: msg,
        type: type,
        duration: duration||2000
    });
}
// 多维数组转一维数组
const flatten = (arr,temp) => {
    return  arr.reduce(function(pre,cur){
        if(!Array.isArray(cur[temp])){
            return [...pre,cur[temp]];
        }else{
            return [...pre,...flatten(cur[temp],temp)]
        }
    },arr)
}
//获取对应参数
const getUrlKey = (name) => {
    return (
      decodeURIComponent(
        (new RegExp("[?|&]" + name + "=" + "([^&;]+?)(&|#|;|$)").exec(location.href) || [, ""])[1].replace(/\+/g, "%20")) || null
    );
}
// 处理时间
const getFormatDate = (date) =>{
    let time = date;
    if(time && time.indexOf('-') > 0){
        time = new Date(date.replace(/\-/g, '/'))
    }
    let year = time.getFullYear(),
        month = time.getMonth() + 1,
        day = time.getDate(),
        dateTime = date.split(' ')[1].split(':').splice(0,2).join(':'),
        nowDate = new Date(),
        nowYear = nowDate.getFullYear(),
        nowMonth = nowDate.getMonth() + 1,
        nowDay = nowDate.getDate(),
        lastDay = new Date(year, month, 0).getDate(),
        htmlDate = ''
    htmlDate = year + '/' + month + '/' + day
    if(year == nowYear){
      htmlDate = month + '/' + day + ' ' + dateTime
      if(month == nowMonth && day == nowDay){
        htmlDate = '今天 ' + dateTime
      }else{
        if(month == nowMonth && nowDay > 1 && day == nowDay - 1){
            htmlDate = '昨天 ' + dateTime
        }else if(month > 1 && month < 12 && day == lastDay && month == nowMonth - 1){
            htmlDate = '昨天 ' + dateTime
        }
      }
    }
    return htmlDate
}
// 复制
const copyText = (text = '') => {
	var input = document.createElement('input');
	input.setAttribute('readonly', 'readonly');
	input.setAttribute('value', text);
	document.body.appendChild(input);
	input.select();
	var res = document.execCommand('copy');
	document.body.removeChild(input);
	return res
}
const handleTreeList = (list,temp = 'children') => { // 删除第三级children
    for (var i = 0; i < list.length; i++) {
        if (list[i][temp] && list[i][temp].length < 1) {				// 判断children的数组长度
          list[i][temp] = undefined
        } else if(list[i][temp] && list[i][temp].length > 0) {
          handleTreeList(list[i][temp],temp)
        }
    }
    return list
}
// 对象转为url参数字符串
const objectToQueryString = (obj) => {
    const searchParams = new URLSearchParams();
    for (let key in obj) {
        if (obj.hasOwnProperty(key)) {
            searchParams.append(key, obj[key]);
        }
    }
    return decodeURIComponent(searchParams.toString());
}
// 手机号带*
const securityPhone=(phone)=>{
    var reg=/(1\d{2})(\d{4})(\d{4})/gi;
    if(phone){
        return phone.replace(reg,'$1****$3');
    }
};
// HTML 标签反转义
const escape2Html = str => { 
	var arrEntities = {
		'lt': '<',
		'gt': '>',
		'nbsp': ' ',
		'amp': '&',
		'quot': '"',
		'apos': "'",
		'#39': "'",
		'#183': "·",
		// &apos; 或 &#39;
	};
    let reg = /lt|gt|nbsp|amp|quot|apos|#39|'#183'/g,newStr
    if(str){
        str = str.replace(/&(lt|gt|nbsp|amp|quot|apos|#39|'#183');/ig, function(all, t) {
            return arrEntities[t];
        });
    }
    if(reg.test(str) && str){
        newStr =  escape2Html(str)
    }else{
        newStr = str
    }
    return newStr
}
const checkPhoneNum = (phone) => {
	let myreg = /^[1][3,4,5,6,7,8,9][0-9]{9}$/;
    if(phone.indexOf('*') > -1){
        phone = phone.replace(/\*/g,"0")
    }
	if (!myreg.test(phone) || phone == '') {
		return false;
	} else {
		return true;
	}
}
const getTimeFormat = (time) => {
    let date = time,
        year,month,day
    if(date){
        year = date.getFullYear()
        month = getZero(date.getMonth() + 1)
        day = getZero(date.getDate())
    }
    return year + '-' + month + '-' + day
}
export default {
    getZero,
    messageFun,
    flatten,
    getUrlKey,
    getFormatDate,
    copyText,
    handleTreeList,
    objectToQueryString,
    escape2Html,
    checkPhoneNum,
    getTimeFormat,
    securityPhone
}