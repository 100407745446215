import Vue from 'vue'
import VueRouter from 'vue-router'

Vue.use(VueRouter)

// 路由表
const routes = [{
  path: '/',
  name: 'home',
  meta: {
    title: '获客流水'
  },
  component: resolve => require(['@/views/Home.vue'], resolve)
}]
const router = new VueRouter({
  mode: 'history',
  routes
})

export default router
