/**
 * 请求模块
 */
import axios from 'axios'
import { Message } from 'element-ui'
import Cookies from 'js-cookie'
import Vue from 'vue'

const request = axios.create({
  // 接口的基准路径
  // baseURL: 'https://apidoc.jiaoyubao.cn/mock/10/ajax',
  // baseURL: '//192.168.1.244:8092/ajax',
  baseURL: '//customerflow.seerschool.com/ajax'
})
// 响应拦截器
request.interceptors.request.use(config => {
  if(config.newUrl){
    config.url = config.newUrl + config.url
  }
  // let cookie = Cookies.get('teacher-sid')
  // config.headers['teacherSid'] = cookie
  return config
},error => {
  return Promise.reject(error)
})
request.interceptors.response.use(
  response => {
    const res = response
    if (res.status == 200) {
      return res.data
    } else {
      Message({
        message: res.data.msg || '数据请求失败，请联系管理员或稍后再试',
        type: 'error',
        showClose: true,
        duration: 3 * 1000
      })
      return Promise.reject(new Error(res.data.msg || 'Error'))
    }
  },error => {
    Message({
      message: '数据请求失败，请联系管理员或稍后再试',
      type: 'error',
      showClose: true,
      duration: 3 * 1000
    })
    return Promise.reject(error)
  }
)

export default request
